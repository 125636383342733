h1, h2, h3, h4, h5, h6 {
  color: #293762;
  font-family: "DM Sens", sans-serif;
}
p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400 !important; 
}




.masonry-columns {
  column-count: 3; /* Number of columns you want */
  column-gap: 1rem; /* Adjust the gap between columns */
}

.masonry-columns > * {
  break-inside: avoid; /* Prevents items from breaking across columns */
  margin-bottom: 1rem; /* Adds space between each item */
}
#header ul a.active, #header ul a:hover {
  background: #F2F3F6;
  padding: 8px 16px;
  border-radius: 6px;
}
#header ul a {
  padding: 8px 16px;
}
.mobile-cb:hover {
  background: none !important;
}
img {
  -webkit-user-drag: none;
  user-drag: none;
}

@media (max-height: 780px) {
  .custom-height-c {
    height: 100%;
  }
}